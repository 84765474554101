import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import SchemaOrg from "./SchemaOrg"

function SEO({ description, lang, title, siteUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
            author {
              name
            }
            organization {
              name
              url
              logo
            }
            social {
              twitter
              fbAppID
            }
          }
        }
      }
    `
  )

  const siteTitle = title || site.siteMetadata.title
  const siteDescription = description || site.siteMetadata.description
  const url = siteUrl || site.siteMetadata.siteUrl
  const image = site.siteMetadata.image

  return (
    <Fragment>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        titleTemplate={`%s | Fanomed clinics - Медицинская клиника для обслуживания вашей семьи или компании`}
      >
        <title>{title}</title>
        <meta name="description" content={siteDescription} />
        <meta name="image" content={image} />
        <link rel="canonical" href={url} />

        <meta property="og:url" content={url} />
        <meta property="og:title" content={siteTitle} />
        <meta property="og:description" content={siteDescription} />
        <meta property="og:image" content={image} />
        <meta property="fb:app_id" content={site.siteMetadata.social.fbAppID} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:creator"
          content={site.siteMetadata.social.twitter}
        />
        <meta name="twitter:title" content={siteTitle} />
        <meta name="twitter:description" content={siteDescription} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <SchemaOrg
        url={url}
        title={site.siteMetadata.title}
        defaultTitle={title}
      />
    </Fragment>
  )
}

SEO.defaultProps = {
  lang: `en`,
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default SEO
